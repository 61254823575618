import React, { lazy, useEffect } from 'react';
import { Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';

import * as PATH from './paths';

import PrivateRoute from './privateRoute';

import MaintenanceRoute from './maintenanceRoute';

import MainPage from './main';
import InstallPage from './install';
import HomePage from './home';
import FriendsPage from './friends';
import ProfilePage from './profile';
import PaymentsPage from './PaymentsPage';
import PaymentPage from './profile/payments/payment';
import RedeemInvitationPage from './profile/redeem-invitation';
import InvitationsPage from './contacts/invitations';
import InvitationPage from './invitation';
import NotFoundPage from './not_found';
import FailScreenPage from './fail_screen';
import ExchangePage from './exchange';
import FeeRatesPage from './fees/id';
import FeeRangesDefaultPage from './fee-ranges-default';
import RedirectWhenLoggedInRoute from './redirectWhenLoggedInRoute';
import AssistantsPage from './assistants';
import ExchangeRatesPage from './profile/exchange-rates';
import SubscriptionsPage from './profile/subscriptions';
import ExchangeRatesByProviderPage from './profile/exchange-rates/providerId';
import ExchangeRatesMyPage from './profile/exchange-rates/my';
import FriendPage from './friends/id';
import BalanceAssistants from './balance/id/assistants';
import BalanceHistory from './balance/id/history';
import PublicPage from './public';
import PaymentChatHistory from './payment/id/chat-history';

import TaskMainPage from './task';
import TasksPage from './tasks';
import TaskPage from "./task/id";
import TaskHistoryPage from './task/history';
import checkIsWebApp from '../utils/checkIsWebApp';
import { EXCHANGE_MAIN, FRIENDS, PROFILE, TASK_MAIN } from './paths';
import NotificationsPage from './profile/notifications';

const MaintenancePage = lazy(() => import('./maintenance'));

export const Routing = () => {
  const isWebApp = checkIsWebApp();
  const navigate = useNavigate();
  const location = useLocation();

  const documentHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty('--doc-height', `${window.innerHeight - 1}px`);
  };

  const updateViewportHeight = (newHeight: number) => {
    let viewportMetaTag = document.querySelector('meta[name="viewport"]') as HTMLMetaElement;
    if (viewportMetaTag) {
      let content = viewportMetaTag.getAttribute('content') || '';
      let newContent = content.replace(/height=\d+/, `height=${newHeight}`);
      if (!/height=/.test(newContent)) {
        newContent += `, height=${newHeight}`;
      }
      viewportMetaTag.setAttribute('content', newContent);
    } else {
      viewportMetaTag = document.createElement('meta');
      viewportMetaTag.name = 'viewport';
      viewportMetaTag.content = `width=device-width, height=${newHeight}, initial-scale=1.0`;
      document.head.appendChild(viewportMetaTag);
    }
  };

  useEffect(() => {
    if (isWebApp) {
      const webApp = window.Telegram.WebApp;
      if (!webApp?.isExpanded) {
        updateViewportHeight(webApp.viewportStableHeight);
        webApp?.expand();
      } else {
        updateViewportHeight(webApp.viewportStableHeight * 0.703);
      }
      if (webApp?.isVersionAtLeast('6.1')) {
        webApp?.setHeaderColor('#000000');
        webApp?.setBackgroundColor('#ffffff');
      }
    }

    window.addEventListener('resize', documentHeight);

    documentHeight();

    return () => {
      window.removeEventListener('resize', documentHeight);
    };
  }, []);

  const goBack = (): void => {
    navigate(-1);
  };

  useEffect(() => {
    if (isWebApp) {
      const webApp = window.Telegram.WebApp;
      webApp.BackButton.onClick(goBack);
    }
  }, []);

  useEffect(() => {
    if (isWebApp) {
      const webApp = window.Telegram.WebApp;
      if ([PROFILE, FRIENDS, EXCHANGE_MAIN].includes(location.pathname) || location.pathname.startsWith(TASK_MAIN)) {
        webApp.BackButton.hide();
      } else {
        webApp.BackButton.show();
      }
    }
  }, [location]);

  return (
    <Routes>
      <Route element={<RedirectWhenLoggedInRoute />}>
        <Route path={PATH.HOME} element={<MainPage />} />
        <Route path={PATH.HOMERU} element={<MainPage />} />
        <Route path={PATH.HOMEEN} element={<MainPage />} />
        <Route path={PATH.MAINPAGE} element={<MainPage />} />
        <Route path={PATH.MAINPAGERU} element={<MainPage />} />
        <Route path={PATH.MAINPAGEEN} element={<MainPage />} />
        <Route path={PATH.RESEARCH} element={<MainPage />} />
        <Route path={PATH.RESEARCHRU} element={<MainPage />} />
        <Route path={PATH.RESEARCHEN} element={<MainPage />} />
        <Route path={PATH.BLOG} element={<MainPage />} />
        <Route path={PATH.BLOGRU} element={<MainPage />} />
        <Route path={PATH.BLOGEN} element={<MainPage />} />
        <Route path={PATH.JOIN} element={<MainPage />} />
        <Route path={PATH.JOINRU} element={<MainPage />} />
        <Route path={PATH.JOINEN} element={<MainPage />} />
        <Route path={PATH.INSTALL} element={<MainPage />} />
        <Route path={PATH.INSTALLEN} element={<MainPage />} />
        <Route path={PATH.INSTALLRU} element={<MainPage />} />
      </Route>

      <Route path={PATH.LOGIN} element={<Navigate to="/" replace />} />
      <Route path={PATH.INSTALL_OLD} element={<InstallPage />} />
      <Route path={PATH.INVITATION} element={<InvitationPage />} />
      <Route path={PATH.PUBLIC} element={<PublicPage />} />

      <Route element={<PrivateRoute />}>
        <Route path={PATH.EXCHANGE_MAIN} element={<HomePage />} />
        <Route path={PATH.PROFILE} element={<ProfilePage />} />
        <Route path={PATH.EXCHANGE} element={<ExchangePage />} />

        <Route path={PATH.TASK_MAIN} element={<TaskMainPage />} />
        <Route path={PATH.TASKS} element={<TasksPage />} />
        <Route path={PATH.TASK} element={<TaskPage />} />
        <Route path={PATH.TASK_HISTORY} element={<TaskHistoryPage />} />

        <Route path={PATH.FRIENDS} element={<FriendsPage />} />
        <Route path={PATH.FRIEND} element={<FriendPage />} />

        <Route path={PATH.BALANCE_ASSISTANT} element={<BalanceAssistants />} />
        <Route path={PATH.BALANCE_HISTORY} element={<BalanceHistory />} />

        <Route path={PATH.ASSISTANTS} element={<AssistantsPage />} />

        <Route path={PATH.PAYMENTS} element={<PaymentsPage />} />
        <Route path={PATH.PAYMENT} element={<PaymentPage />} />
        <Route path={PATH.PAYMENT_CHAT_HISTORY} element={<PaymentChatHistory />} />

        <Route path={PATH.INVITATIONS} element={<InvitationsPage />} />
        <Route
          path={PATH.REDEEM_INVITATION}
          element={<RedeemInvitationPage />}
        />
        <Route path={PATH.FEES} element={<FeeRatesPage />} />
        <Route
          path={PATH.FEE_RANGE_DEFAULTS}
          element={<FeeRangesDefaultPage />}
        />

        <Route path={PATH.EXCHANGE_RATES} element={<ExchangeRatesPage />} />
        <Route
          path={PATH.EXCHANGE_RATES_MY}
          element={<ExchangeRatesMyPage />}
        />
        <Route
          path={PATH.EXCHANGE_RATES_BY_PROVIDER}
          element={<ExchangeRatesByProviderPage />}
        />

        <Route element={<MaintenanceRoute />}>
          <Route path={PATH.MAINTENANCE} element={<MaintenancePage />} />
        </Route>

        <Route
          path={PATH.SUBSCRIPTIONS}
          element={<SubscriptionsPage />}
        />

        <Route
          path={PATH.NOTIFICATIONS}
          element={<NotificationsPage />}
        />
      </Route>

      <Route path={PATH.NOT_FOUND} element={<NotFoundPage />} />
      <Route path={PATH.FAIL_SCREEN} element={<FailScreenPage />} />
      <Route path={'*'} element={<NotFoundPage />} />
    </Routes>
  );
};
