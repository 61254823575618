/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AssistantCreatingRequest {
  /** @format int64 */
  assistantUserId: number;
}
export enum Authority {
  Invite = 'INVITE',
  Provide = 'PROVIDE',
  ModifyRates = 'MODIFY_RATES',
  Maintain = 'MAINTAIN',
  SendPaymentEvents = 'SEND_PAYMENT_EVENTS',
  Exchange = 'EXCHANGE',
  MultipleProviders = 'MULTIPLE_PROVIDERS',
  UploadMethods = 'UPLOAD_METHODS',
  BatchUpload = 'BATCH_UPLOAD',
  NotifyAllUsers = 'NOTIFY_ALL_USERS',
  BalanceService = 'BALANCE_SERVICE',
  TelegramService = 'TELEGRAM_SERVICE',
  OpenaiService = 'OPENAI_SERVICE',
}
export interface AuthorizationCodeDto {
  authorizationCode: string;
}
export interface AvailableExchangeOptions {
  buyCurrencyCodes: string[];
  buyMethods: PaymentMethodDto[];
  sellCurrencyCodes: string[];
  sellMethods: PaymentMethodDto[];
}
export interface AvailableSkuParameter {
  availableValues: AvailableSkuParameterValue[];
  localizedName: string;
  name: string;
  type: SkuParameterType;
}
export interface AvailableSkuParameterValue {
  localizedValue: string;
  value: string;
}
export interface BalanceAssistantsDto {
  assistants: BalanceParticipantResponseDto[];
  balanceInfo: BalanceInfoDto;
}
export interface BalanceBucketDto {
  comment?: string;
  /** @format int64 */
  id: number;
  name: string;
  shortname: string;
  totalBalance: number;
  transactions: BalanceTransactionDto[];
  type: string;
}
export interface BalanceBucketNoHistoryDto {
  comment?: string;
  /** @format int64 */
  id: number;
  name: string;
  shortname: string;
  totalBalance: number;
  type: string;
}
export interface BalanceDto {
  balanceName?: string;
  balancePhotoUrl?: string;
  balanceType: BalanceType;
  buckets: BalanceBucketDto[];
  comment?: string;
  /** @format int64 */
  id: number;
  isClosed: boolean;
  participants: BalanceParticipantDto[];
  telegramChats: BalanceTgChatDto[];
}
export interface BalanceHistoryResponseDtoV2 {
  balanceInfoDto: BalanceInfoDto;
  transactions: BalanceTransactionHistoryResponseDto[];
}
export interface BalanceInfoDto {
  balanceName?: string;
  balancePhotoUrl?: string;
  balanceType: BalanceType;
  comment?: string;
  /** @format int64 */
  id: number;
  isClosed: boolean;
}
export interface BalanceParticipantDto {
  /** @format int64 */
  balancePriority: number;
  /** @format int64 */
  id: number;
  name: string;
  role: BalanceParticipantRole;
  /** @format int64 */
  tgId: number;
  tgUsername?: string;
}
export interface BalanceParticipantResponseDto {
  role: BalanceParticipantRole;
  user: UserDto;
}
export enum BalanceParticipantRole {
  User1 = 'USER1',
  User2 = 'USER2',
  User1Assistant = 'USER1_ASSISTANT',
  User2Assistant = 'USER2_ASSISTANT',
}
export interface BalanceResponseDto {
  balanceName?: string;
  balancePhotoUrl?: string;
  balanceType: BalanceType;
  buckets: BalanceBucketNoHistoryDto[];
  canArchive: boolean;
  canAssist: boolean;
  canDelete: boolean;
  canEdit: boolean;
  canTransfer: boolean;
  comment?: string;
  /** @format int64 */
  id: number;
  isClosed: boolean;
  participants: BalanceParticipantResponseDto[];
  tgLink?: string;
  tgDeepLink?: string;
}
export interface BalanceSimplifiedDto {
  user1: BalanceUserDto;
  user2: BalanceUserDto;
  balanceName?: string;
  balancePhotoUrl?: string;
  balanceType: BalanceType;
  buckets: BalanceBucketNoHistoryDto[];
  canArchive: boolean;
  canAssist: boolean;
  canDelete: boolean;
  canEdit: boolean;
  comment?: string;
  /** @format int64 */
  id: number;
  isClosed: boolean;
  role: BalanceParticipantRole;
}
export interface BalanceSummaryDto {
  currencyCode: string;
  value: number;
}
export interface BalanceTgChatDto {
  /** @format int64 */
  chatId: number;
  chatLink?: string;
  chatName: string;
}
export interface BalanceTransactionDto {
  authorUser: BalanceParticipantDto;
  comment: string;
  /** @format date-time */
  createdAt: string;
  /** @format int64 */
  id: number;
  /** @format int64 */
  telegramChatId?: number;
  value: number;
}
export interface BalanceTransactionHistoryResponseDto {
  authorUser: UserDto;
  bucketName: string;
  comment: string;
  /** @format date-time */
  createdAt: string;
  /** @format int64 */
  id: number;
  value: number;
}
export enum BalanceType {
  Project = 'PROJECT',
  Payment = 'PAYMENT',
  Default = 'DEFAULT',
}
export interface BalanceUserDto {
  /** @format int64 */
  id: number;
  name: string;
  /** @format int64 */
  tgId: number;
  tgUsername?: string;
}
export interface BalanceWOBucketsDto {
  user1: BalanceUserDto;
  user2: BalanceUserDto;
  balanceName?: string;
  balancePhotoUrl?: string;
  balanceType: BalanceType;
  comment?: string;
  /** @format int64 */
  id: number;
  isClosed: boolean;
  participants: BalanceParticipantDto[];
  telegramChats: BalanceTgChatDto[];
}
export interface BetterExchangeRateWidgetDto {
  betterExchangeRate?: number;
  newBuyCurrencyAmount?: number;
  newSellCurrencyAmount?: number;
  nextRangeAmount?: number;
  nextRangeCurrency?: CurrencyDto;
}
export interface BulkNotificationDto {
  message: string;
  usersLanguage?: Language;
}
export interface ChatCreationResponseDto {
  /** @format int64 */
  chatId: number;
  inviteLink: string;
}
export interface ContactBalanceSummary {
  balanceSummaryList: BalanceSummaryDto[];
  /** @format int32 */
  plusNumber: number;
}
export interface ContactDto {
  contactBalanceSummary: ContactBalanceSummary;
  userDto: UserDto;
}
export interface ContactModificationRequest {
  customName?: string;
  customPhotoUrl?: string;
}
export enum ContactRelationType {
  Friend = 'FRIEND',
  Customer = 'CUSTOMER',
  Provider = 'PROVIDER',
  CustomerProvider = 'CUSTOMER_PROVIDER',
  NotContact = 'NOT_CONTACT',
}
export enum ContactType {
  Telegram = 'TELEGRAM',
  TelegramLink = 'TELEGRAM_LINK',
  TelegramId = 'TELEGRAM_ID',
  Instagram = 'INSTAGRAM',
  X = 'X',
  Whatsapp = 'WHATSAPP',
}
export interface CreateBalanceTransactionRequest {
  /** @format int64 */
  balanceId?: number;
  bucketName: string;
  comment: string;
  /** @format int64 */
  contactId: number;
  value: number;
}
export interface CreateTransactionalPaymentDto {
  amount: number;
  comment: string;
  /** @format int64 */
  contactId: number;
  exchangeUnitName: string;
  /** @format int64 */
  fromBalanceId: number;
}
export interface CrossExchangeWidgetDto {
  betterExchangeRate?: number;
  buyCurrency: ExchangeWidgetCurrencyDto;
  newBuyCurrencyAmount?: number;
  newSellCurrencyAmount?: number;
  nextRangeAmount?: number;
  nextRangeCurrency?: CurrencyDto;
  sellCurrency: ExchangeWidgetCurrencyDto;
}
export type CurrencyDto = ExchangeUnitDto & {
  code: string;
  paymentMethod: PaymentMethodDto;
};
export interface CurrencyInfoSimplifiedDto {
  code: string;
  description?: string;
  paymentMethod: PaymentMethodDto;
  picture?: string;
}
export enum CurrencyInfoValuableType {
  Currency = 'CURRENCY',
}
export interface CurrencyInformationResponseDto {
  code: string;
  /** @format int32 */
  decimalPlaces: number;
  description?: string;
  /** @format int32 */
  greyDecimalPlaces: number;
  paymentMethods: PaymentMethodsResponseDto[];
  picture?: string;
  symbol?: string;
  valuableType: CurrencyInfoValuableType;
  /** @format int32 */
  whiteDecimalPlaces: number;
}
export interface CurrencyInformationResponseDtoV3 {
  code: string;
  /** @format int32 */
  greyDecimalPlaces: number;
  paymentMethods: PaymentMethodsResponseDtoV3[];
  /** @format int32 */
  whiteDecimalPlaces: number;
}
export interface CurrencyPaymentMethodInfo {
  canBuy: boolean;
  canSell: boolean;
  methodName: string;
}
export type CurrencyResponseDto = ExchangeUnitResponseDto & {
  code: string;
  paymentMethod: PaymentMethodResponseDto;
  picture?: string;
  description?: string;
  exchangeUnitId: number;
};
export interface CustomerExchangerInitWithLocalStorageRequest {
  buyCurrencyAmount?: number;
  buyCurrencyCode: string;
  buyMethodName: string;
  buyMethodType: PaymentMethodType;
  /** @format int64 */
  customerId: number;
  exchangerType?: ExchangerType;
  sellCurrencyAmount?: number;
  sellCurrencyCode: string;
  sellMethodName: string;
  sellMethodType: PaymentMethodType;
}
export interface EventInvitedDto {
  invitedName: string;
  /** @format int64 */
  invitedTgId: number;
  invitedUsername?: string;
  /** @format int64 */
  invitingTgId: number;
  telegramChatId: string;
}
export interface EventNotificationDto {
  amount?: number;
  bucketShortname?: string;
  comment?: string;
  paymentEvent: PaymentEventNotification;
  telegramChatId: string;
  userTelegramId: string;
}
export interface ExchangeCalculationResponse {
  availableTabs: AvailableExchangeOptions;
  buyCurrencyAmount: number;
  crossExchangeWidgetData: CrossExchangeWidgetDto;
  exchanges: ExchangeRateWidgetDto[];
  intermediaryExchanges: ExchangeRateWidgetDto[];
  sellCurrencyAmount: number;
}
export interface ExchangeCalculationResponseV3 {
  paymentFormDtoV3: PaymentFormDtoV3;
}
export interface ExchangeCalculationResponseV5 {
  paymentForm: PaymentFormDtoV5;
}
export interface ExchangeOfferDto {
  buyExchangeUnit: Partial<CurrencyDto & GoodsDto & SkuIdDto>;
  exchangeRate: number;
  /** @format int64 */
  id?: number;
  privacySetting: PrivacySetting;
  sellExchangeUnit: Partial<CurrencyDto & GoodsDto & SkuIdDto>;
  whitelist: UserDto[];
}
export interface ExchangeRateDto {
  buyCurrency: Partial<CurrencyDto & GoodsDto & SkuIdDto>;
  exchangeRate: number;
  /** @format int64 */
  id?: number;
  isActive: boolean;
  sellCurrency: Partial<CurrencyDto & GoodsDto & SkuIdDto>;
}
export interface ExchangeRateInheritedDto {
  exchangeRate: ExchangeRateDto;
  isInherited: boolean;
}
export interface ExchangeRateWidgetDto {
  buyCurrency: ExchangeWidgetCurrencyDto;
  sellCurrency: ExchangeWidgetCurrencyDto;
}
export interface ExchangeRatesByUserDto {
  exclusive?: boolean;
  rates: UploadExchangeRateDto[];
  skuDescriptions?: SkuDescription[];
  skuOffers?: SkuOffer[];
  /** @format int64 */
  userId: number;
}
export interface ExchangeRatesUploadDto {
  exclusive?: boolean;
  rates: UploadExchangeRateDto[];
  skuDescriptions?: SkuDescription[];
  skuOffers?: SkuOffer[];
}
export type ExchangeUnitDto = object;
export type ExchangeUnitResponseDto = object;
export interface ExchangeWidgetCurrencyDto {
  amount: number;
  canBeChanged: boolean;
  code: string;
}
export interface ExchangeWidgetDto {
  buyCurrency: ExchangeWidgetCurrencyDto;
  sellCurrency: ExchangeWidgetCurrencyDto;
}
export interface ExchangerCalculateRequest {
  /** @format int64 */
  customerId?: number;
  exchangerFormData: ExchangerFormData;
  /** @format int64 */
  providerId: number;
}
export interface ExchangerFormData {
  buyExchangeUnitAmount: number;
  buyExchangeUnitDto: Partial<CurrencyDto & GoodsDto & SkuIdDto>;
  currentTab: string;
  sellExchangeUnitAmount: number;
  sellExchangeUnitDto: Partial<CurrencyDto & GoodsDto & SkuIdDto>;
  targetField: TargetField;
}
export interface ExchangerInitializationDto {
  currencies: CurrencyInformationResponseDto[];
  exchangeCalculationResponse: ExchangeCalculationResponse;
  paymentFormDto?: PaymentFormDto;
  tabsToHide: CurrencyInfoValuableType[];
}
export interface ExchangerInitializationDtoV3 {
  currentFormData: PaymentFormDtoV3;
  currentTab: string;
  tabs: ExchangerTab[];
}
export interface ExchangerInitializationDtoV4 {
  tabs: ExchangerTabV4[];
}
export interface ExchangerInitializationDtoV5 {
  tabs: ExchangerTabV5[];
}
export interface ExchangerInitializeRequest {
  /** @format int64 */
  customerId?: number;
  exchangerFormData?: ExchangerFormData;
  language?: Language;
  preferredCity?: string;
  preferredFormDataList?: ExchangerFormData[];
  /** @format int64 */
  providerId: number;
}
export interface ExchangerTab {
  currencies: CurrencyInformationResponseDtoV3[];
  goods: GoodsInformationResponseDto[];
  localizedName: string;
  name: string;
  tabType: TabType;
}
export interface ExchangerTabV4 {
  currencies: CurrencyInformationResponseDtoV3[];
  goods: GoodsInCityResponseDto[];
  initialData: PaymentFormDtoV3;
  localizedName: string;
  name: string;
  tabType: TabType;
}
export interface ExchangerTabV5 {
  currencies: CurrencyInformationResponseDtoV3[];
  goods: GoodsInCityResponseDto[];
  initialData: PaymentFormDtoV5;
  localizedName: string;
  name: string;
  tabType: TabType;
}
export enum ExchangerType {
  Currency = 'CURRENCY',
  Goods = 'GOODS',
}
export interface FeeRateRangeDto {
  feeRate: number;
  /** @format int64 */
  id: number;
  lowerBound: number;
}
export interface FeeRateRangeDtoV2 {
  feeRate: number;
  /** @format int64 */
  id: number;
  lowerBound: number;
  type: string;
}
export interface GenerateInvitationRequest {
  feeRateRanges?: InvitationFeeRateRangeDto[];
  language?: Language;
  paymentInvitationForm?: PaymentInvitationForm;
  /** @format int64 */
  providerId?: number;
  trustAmount?: number;
  userRelationType: UserRelationType;
  reusable?: boolean;
}
export interface GenerateInvitationResponse {
  botLink: string;
  invitationToken: string;
  deepLink: string;
}
export interface GoodsCityResponseDto {
  availableSkuParameters: AvailableSkuParameter[];
  cityName: string;
  localizedCityName: string;
  measureUnits: MeasureUnitDto[];
  subcategories: string[];
  subcategoriesWithLocalization: SubcategoryDto[];
}
export type GoodsDto = ExchangeUnitDto & {
  name: string;
  city: string;
  measureUnit: string;
  skuParameters: SkuParameterDto[];
};
export interface GoodsInCityResponseDto {
  cityName: string;
  localizedCityName: string;
  subcategories: SubcategoryDtoV4[];
}
export interface GoodsInformationResponseDto {
  category: string;
  cities: GoodsCityResponseDto[];
  description?: string;
  localizedCategory: string;
  localizedName: string;
  localizedSubcategory: string;
  name: string;
  picture?: string;
  subcategory: string;
}
export type GoodsResponseDto = ExchangeUnitResponseDto & {
  name: string;
  city: string;
  measureUnit: string;
  localizedName: string;
  localizedCity: string;
  localizedMeasureUnit: string;
  picture?: string;
  description?: string;
  /** @uniqueItems true */
  skuParameters: SkuParameterResponseDto[];
  skuIdentifier: string;
};
export type SkuIdDto = ExchangeUnitDto & {
  skuIdentifier: string;
};
export interface InheritanceWhitelistRequest {
  /** @format int64 */
  exchangeOfferId: number;
  /** @format int64 */
  providerId: number;
  /** @format int64 */
  relativeId: number;
}
export interface IntermediaryExchangeBotResponseDto {
  buyCurrencyAmount?: number;
  buyCurrencyCode?: string;
  customerTgId: string;
  providerTgId: string;
  sellCurrencyAmount?: number;
  sellCurrencyCode?: string;
}
export interface IntermediaryExchangeDto {
  buyCurrency: Partial<CurrencyDto & GoodsDto & SkuIdDto>;
  buyCurrencyAmount: number;
  /** @format int32 */
  buyCurrencyExchangePriority: number;
  /** @format int32 */
  buyCurrencyExchangeWidgetRateScale: number;
  customer: UserDto;
  exchangeRate: number;
  feeAmount?: number;
  feeCurrency?: Partial<CurrencyDto & GoodsDto & SkuIdDto>;
  feeRate?: number;
  /** @format int64 */
  id?: number;
  provider: UserDto;
  sellCurrency: Partial<CurrencyDto & GoodsDto & SkuIdDto>;
  sellCurrencyAmount: number;
  /** @format int32 */
  sellCurrencyExchangePriority: number;
  /** @format int32 */
  sellCurrencyExchangeWidgetRateScale: number;
}
export interface IntermediaryExchangeDtoV4 {
  buyAmount?: number;
  buyExchangeUnit?: Partial<CurrencyDto & GoodsDto & SkuIdDto>;
  /** @format int32 */
  buyExchangeUnitPriority?: number;
  /** @format int32 */
  buyExchangeUnitWidgetRateScale?: number;
  customer: UserDto;
  exchangeRate?: number;
  feeAmount?: number;
  feeCurrency?: Partial<CurrencyDto & GoodsDto & SkuIdDto>;
  feeRate?: number;
  /** @format int64 */
  id?: number;
  provider: UserDto;
  sellAmount?: number;
  sellExchangeUnit?: Partial<CurrencyDto & GoodsDto & SkuIdDto>;
  /** @format int32 */
  sellExchangeUnitPriority?: number;
  /** @format int32 */
  sellExchangeUnitWidgetRateScale?: number;
}
export interface InvitationFeeRateRangeDto {
  feeRate: number;
  lowerBound: number;
  type: string;
}
export interface InvitationInfoDto {
  invitedBy: UserDto;
  provider?: UserDto;
  trustAmount?: number;
}
export interface InvitationTokenDto {
  /** @format date-time */
  createdAt: string;
  createdBy: UserDto;
  feeRateRange?: InvitationFeeRateRangeDto[];
  language?: Language;
  token: string;
  /** @format int64 */
  ttlSec: number;
  used: boolean;
  /** @format date-time */
  usedAt?: string;
  usedBy?: UserDto;
  userRelationType: UserRelationType;
  botLink: string;
  botDeepLink: string;
}
export interface JwtTokenResponse {
  jwt: string;
  refreshToken: string;
  /** @format int64 */
  userId: number;
}
export interface Keys {
  p256dh: string;
  auth: string;
}
export enum Language {
  Ru = 'ru',
  En = 'en',
}
export interface LocaleDto {
  locale: Language;
}
export enum LocalizationType {
  MeasureUnit = 'MEASURE_UNIT',
  SkuParameterName = 'SKU_PARAMETER_NAME',
  SkuParameterValue = 'SKU_PARAMETER_VALUE',
  City = 'CITY',
  Category = 'CATEGORY',
  Subcategory = 'SUBCATEGORY',
  GoodsName = 'GOODS_NAME',
  PaymentMethodType = 'PAYMENT_METHOD_TYPE',
  PaymentMethodName = 'PAYMENT_METHOD_NAME',
}
export interface LocalizationUploadDto {
  language: Language;
  originalValue: string;
  translation: string;
  type: LocalizationType;
}
export interface MeasureUnitDto {
  localizedName: string;
  name: string;
}
export interface OpenAIConfig {
  model: string;
  prompts: Prompts;
  /** @format int32 */
  score_threshold?: number;
}
export interface OpenAIRequestHistoryDto {
  errorMessage?: string;
  request?: string;
  requestKey: string;
  /** @format date-time */
  requestStartTime?: string;
  response?: string;
  /** @format int64 */
  responseTime?: number;
  status: OpenAIRequestStatus;
  type: OpenAIRequestType;
}
export enum OpenAIRequestStatus {
  Running = 'RUNNING',
  Completed = 'COMPLETED',
  Error = 'ERROR',
  Timeout = 'TIMEOUT',
}
export enum OpenAIRequestType {
  InitialAssessment = 'INITIAL_ASSESSMENT',
  Answering = 'ANSWERING',
  Prioritization = 'PRIORITIZATION',
}
export interface PageBalanceBucketNoHistoryDto {
  content?: BalanceBucketNoHistoryDto[];
  empty?: boolean;
  first?: boolean;
  last?: boolean;
  /** @format int32 */
  number?: number;
  /** @format int32 */
  numberOfElements?: number;
  pageable?: PageableObject;
  /** @format int32 */
  size?: number;
  sort?: SortObject;
  /** @format int64 */
  totalElements?: number;
  /** @format int32 */
  totalPages?: number;
}
export interface PageBalanceResponseDto {
  content?: BalanceResponseDto[];
  empty?: boolean;
  first?: boolean;
  last?: boolean;
  /** @format int32 */
  number?: number;
  /** @format int32 */
  numberOfElements?: number;
  pageable?: PageableObject;
  /** @format int32 */
  size?: number;
  sort?: SortObject;
  /** @format int64 */
  totalElements?: number;
  /** @format int32 */
  totalPages?: number;
}
export interface PageContactDto {
  content?: ContactDto[];
  empty?: boolean;
  first?: boolean;
  last?: boolean;
  /** @format int32 */
  number?: number;
  /** @format int32 */
  numberOfElements?: number;
  pageable?: PageableObject;
  /** @format int32 */
  size?: number;
  sort?: SortObject;
  /** @format int64 */
  totalElements?: number;
  /** @format int32 */
  totalPages?: number;
}
export interface PageExchangeOfferDto {
  content?: ExchangeOfferDto[];
  empty?: boolean;
  first?: boolean;
  last?: boolean;
  /** @format int32 */
  number?: number;
  /** @format int32 */
  numberOfElements?: number;
  pageable?: PageableObject;
  /** @format int32 */
  size?: number;
  sort?: SortObject;
  /** @format int64 */
  totalElements?: number;
  /** @format int32 */
  totalPages?: number;
}
export interface PageExchangeRateDto {
  content?: ExchangeRateDto[];
  empty?: boolean;
  first?: boolean;
  last?: boolean;
  /** @format int32 */
  number?: number;
  /** @format int32 */
  numberOfElements?: number;
  pageable?: PageableObject;
  /** @format int32 */
  size?: number;
  sort?: SortObject;
  /** @format int64 */
  totalElements?: number;
  /** @format int32 */
  totalPages?: number;
}
export interface PageExchangeRateInheritedDto {
  content?: ExchangeRateInheritedDto[];
  empty?: boolean;
  first?: boolean;
  last?: boolean;
  /** @format int32 */
  number?: number;
  /** @format int32 */
  numberOfElements?: number;
  pageable?: PageableObject;
  /** @format int32 */
  size?: number;
  sort?: SortObject;
  /** @format int64 */
  totalElements?: number;
  /** @format int32 */
  totalPages?: number;
}
export interface PageInvitationTokenDto {
  content?: InvitationTokenDto[];
  empty?: boolean;
  first?: boolean;
  last?: boolean;
  /** @format int32 */
  number?: number;
  /** @format int32 */
  numberOfElements?: number;
  pageable?: PageableObject;
  /** @format int32 */
  size?: number;
  sort?: SortObject;
  /** @format int64 */
  totalElements?: number;
  /** @format int32 */
  totalPages?: number;
}
export interface PagePaymentForListDto {
  content?: PaymentForListDto[];
  empty?: boolean;
  first?: boolean;
  last?: boolean;
  /** @format int32 */
  number?: number;
  /** @format int32 */
  numberOfElements?: number;
  pageable?: PageableObject;
  /** @format int32 */
  size?: number;
  sort?: SortObject;
  /** @format int64 */
  totalElements?: number;
  /** @format int32 */
  totalPages?: number;
}
export interface PagePaymentForListDtoV4 {
  content?: PaymentForListDtoV4[];
  empty?: boolean;
  first?: boolean;
  last?: boolean;
  /** @format int32 */
  number?: number;
  /** @format int32 */
  numberOfElements?: number;
  pageable?: PageableObject;
  /** @format int32 */
  size?: number;
  sort?: SortObject;
  /** @format int64 */
  totalElements?: number;
  /** @format int32 */
  totalPages?: number;
}
export interface PageTaskResponseDto {
  content?: TaskResponseDto[];
  empty?: boolean;
  first?: boolean;
  last?: boolean;
  /** @format int32 */
  number?: number;
  /** @format int32 */
  numberOfElements?: number;
  pageable?: PageableObject;
  /** @format int32 */
  size?: number;
  sort?: SortObject;
  /** @format int64 */
  totalElements?: number;
  /** @format int32 */
  totalPages?: number;
}
export interface PageTransactionResponseDto {
  content?: TransactionResponseDto[];
  empty?: boolean;
  first?: boolean;
  last?: boolean;
  /** @format int32 */
  number?: number;
  /** @format int32 */
  numberOfElements?: number;
  pageable?: PageableObject;
  /** @format int32 */
  size?: number;
  sort?: SortObject;
  /** @format int64 */
  totalElements?: number;
  /** @format int32 */
  totalPages?: number;
}
export interface PageUserDto {
  content?: UserDto[];
  empty?: boolean;
  first?: boolean;
  last?: boolean;
  /** @format int32 */
  number?: number;
  /** @format int32 */
  numberOfElements?: number;
  pageable?: PageableObject;
  /** @format int32 */
  size?: number;
  sort?: SortObject;
  /** @format int64 */
  totalElements?: number;
  /** @format int32 */
  totalPages?: number;
}
export interface Pageable {
  /**
   * @format int32
   * @min 0
   */
  page?: number;
  /**
   * @format int32
   * @min 1
   */
  size?: number;
  sort?: string[];
}
export interface PageableObject {
  /** @format int64 */
  offset?: number;
  /** @format int32 */
  pageNumber?: number;
  /** @format int32 */
  pageSize?: number;
  paged?: boolean;
  sort?: SortObject;
  unpaged?: boolean;
}
export interface PaymentBotResponseDto {
  areBalancesChanged: boolean;
  /** @format int64 */
  balanceId?: number;
  buyExchangeUnitAmount?: number;
  buyExchangeUnitName?: string;
  comment?: string;
  /** @format date-time */
  createdAt: string;
  /** @format int64 */
  fromBalanceId?: number;
  intermediaryExchanges: IntermediaryExchangeBotResponseDto[];
  isPaymentCreatedAfterPaymentBalancesRelease: boolean;
  /** @uniqueItems true */
  paymentParticipants: PaymentParticipantDto[];
  paymentProcessingHistory: PaymentProcessingHistoryEntryDto[];
  paymentStatus: PaymentStatus;
  sellExchangeUnitAmount?: number;
  sellExchangeUnitName?: string;
  title: string;
  type: PaymentType;
}
export interface PaymentChatHistoryResponseDto {
  chatHistoryKey: string;
  /** @format int64 */
  paymentId: number;
}
export interface PaymentConfirmationDto {
  comment?: string;
  details?: string;
  moneyReady: boolean;
  sessionKey: string;
  title?: string;
}
export interface PaymentCreationRequestDto {
  buyCurrency: Partial<CurrencyDto & GoodsDto & SkuIdDto>;
  buyCurrencyAmount: number;
  /** @format int64 */
  providerId: number;
  sellCurrency: Partial<CurrencyDto & GoodsDto & SkuIdDto>;
  sellCurrencyAmount: number;
  targetField: TargetField;
  /** @format int64 */
  userId: number;
}
export interface PaymentCreationRequestDtoV3 {
  buyCurrencyAmount: number;
  buyExchangeUnit: Partial<CurrencyDto & GoodsDto & SkuIdDto>;
  /** @format int64 */
  providerId: number;
  sellCurrencyAmount: number;
  sellExchangeUnit: Partial<CurrencyDto & GoodsDto & SkuIdDto>;
  targetField: TargetField;
  /** @format int64 */
  userId: number;
  utmTag?: string;
}
export interface PaymentCreationResponseDto {
  buyAmount: number;
  buyCurrency: CurrencyInfoSimplifiedDto;
  customer: UserDto;
  originalBuyAmount?: number;
  originalSellAmount?: number;
  /** @format date-time */
  paymentExpirationTime: string;
  provider: UserDto;
  sellAmount: number;
  sellCurrency: CurrencyInfoSimplifiedDto;
  sessionKey: string;
}
export interface PaymentCreationResponseDtoV3 {
  buyExchangeUnit: Partial<CurrencyResponseDto & GoodsResponseDto>;
  buyExchangeUnitAmount: number;
  comment?: string;
  customer: UserDto;
  /** @format int64 */
  fromBalanceId?: number;
  originalBuyAmount?: number;
  originalSellAmount?: number;
  /** @format date-time */
  paymentExpirationTime: string;
  paymentStatus: PaymentStatus;
  provider: UserDto;
  sellExchangeUnit: Partial<CurrencyResponseDto & GoodsResponseDto>;
  sellExchangeUnitAmount: number;
  sessionKey: string;
  telegramInviteLink?: string;
  title: string;
  type: PaymentType;
}
export interface PaymentCreationResponseDtoV4 {
  buyExchangeUnit?: Partial<CurrencyResponseDto & GoodsResponseDto>;
  buyExchangeUnitAmount?: number;
  comment?: string;
  customer: UserDto;
  /** @format int64 */
  fromBalanceId?: number;
  originalBuyAmount?: number;
  originalSellAmount?: number;
  /** @format date-time */
  paymentExpirationTime: string;
  paymentStatus: PaymentStatus;
  provider: UserDto;
  sellExchangeUnit?: Partial<CurrencyResponseDto & GoodsResponseDto>;
  sellExchangeUnitAmount?: number;
  sessionKey: string;
  telegramInviteLink?: string;
  title: string;
  type: PaymentType;
}
export interface PaymentDetailsRequestDto {
  currencyCode: string;
  details: string;
  paymentMethodName: string;
}
export interface PaymentDetailsResponseDto {
  details: string;
  isPrimary: boolean;
}
export interface PaymentDto {
  /** @format int64 */
  balanceId?: number;
  chatHistoryKey?: string;
  chatId?: string;
  /** @format date-time */
  closedAt?: string;
  comment?: string;
  /** @format date-time */
  createdAt: string;
  crossExchangeWidgetData: ExchangeWidgetDto;
  details?: string;
  exchangeRates: ExchangeWidgetDto[];
  /** @format int64 */
  fromBalanceId?: number;
  /** @format int64 */
  id: number;
  intermediaryExchanges: IntermediaryExchangeDto[];
  isMoneyReady: boolean;
  /** @format date-time */
  paymentDeadline?: string;
  paymentProcessingHistory: PaymentProcessingHistoryEntryDto[];
  paymentStatus: PaymentStatus;
  provider: UserDto;
  requester: UserDto;
  telegramInviteLink?: string;
  title: string;
  type: PaymentType;
}
export interface PaymentDtoV4 {
  /** @format int64 */
  balanceId?: number;
  chatHistoryKey?: string;
  chatId?: string;
  /** @format date-time */
  closedAt?: string;
  comment?: string;
  /** @format date-time */
  createdAt: string;
  crossExchangeWidgetData?: ExchangeWidgetDto;
  details?: string;
  exchangeRates: ExchangeWidgetDto[];
  /** @format int64 */
  fromBalanceId?: number;
  /** @format int64 */
  id: number;
  intermediaryExchanges: IntermediaryExchangeDtoV4[];
  isMoneyReady: boolean;
  /** @format date-time */
  paymentDeadline?: string;
  paymentProcessingHistory: PaymentProcessingHistoryEntryDto[];
  paymentStatus: PaymentStatus;
  provider: UserDto;
  requester: UserDto;
  telegramInviteLink?: string;
  telegramDeepLink?: string;
  title: string;
  type: PaymentType;
}
export enum PaymentEvent {
  Initialized = 'INITIALIZED',
  ProviderConfirmedReceptionAll = 'PROVIDER_CONFIRMED_RECEPTION_ALL',
  ProviderConfirmedReceptionPart = 'PROVIDER_CONFIRMED_RECEPTION_PART',
  ProviderConfirmedSending = 'PROVIDER_CONFIRMED_SENDING',
  ProviderCancellation = 'PROVIDER_CANCELLATION',
  CustomerConfirmedReceptionAll = 'CUSTOMER_CONFIRMED_RECEPTION_ALL',
  CustomerConfirmedReceptionPart = 'CUSTOMER_CONFIRMED_RECEPTION_PART',
  CustomerConfirmedSending = 'CUSTOMER_CONFIRMED_SENDING',
  CustomerCancellation = 'CUSTOMER_CANCELLATION',
  SuccessKeptOnBalance = 'SUCCESS_KEPT_ON_BALANCE',
  RequestKeepOnBalance = 'REQUEST_KEEP_ON_BALANCE',
  SuccessTotalBalance = 'SUCCESS_TOTAL_BALANCE',
  Transaction = 'TRANSACTION',
  ProviderRequestedCancellation = 'PROVIDER_REQUESTED_CANCELLATION',
  CustomerRequestedCancellation = 'CUSTOMER_REQUESTED_CANCELLATION',
  ProviderConfirmedCancellation = 'PROVIDER_CONFIRMED_CANCELLATION',
  CustomerConfirmedCancellation = 'CUSTOMER_CONFIRMED_CANCELLATION',
}
export enum PaymentEventNotification {
  ConfirmedReceptionAll = 'CONFIRMED_RECEPTION_ALL',
  ConfirmedReceptionPart = 'CONFIRMED_RECEPTION_PART',
  ConfirmedSending = 'CONFIRMED_SENDING',
  Cancellation = 'CANCELLATION',
  ConfirmedKeepOnBalance = 'CONFIRMED_KEEP_ON_BALANCE',
  TotalBalanceZero = 'TOTAL_BALANCE_ZERO',
  RequestKeepOnBalance = 'REQUEST_KEEP_ON_BALANCE',
  CancellationRequest = 'CANCELLATION_REQUEST',
  ConfirmedCancellation = 'CONFIRMED_CANCELLATION',
}
export interface PaymentForListDto {
  buyCurrency: Partial<CurrencyDto & GoodsDto & SkuIdDto>;
  buyCurrencyAmount: number;
  comment?: string;
  /** @format date-time */
  createdAt: string;
  feeAmount?: number;
  feeCurrency?: Partial<CurrencyDto & GoodsDto & SkuIdDto>;
  /** @format int64 */
  fromBalanceId?: number;
  /** @format int64 */
  id: number;
  paymentStatus: PaymentStatus;
  provider: UserDto;
  requester: UserDto;
  sellCurrency: Partial<CurrencyDto & GoodsDto & SkuIdDto>;
  sellCurrencyAmount: number;
  title: string;
  type: PaymentType;
}
export interface PaymentForListDtoV4 {
  buyAmount?: number;
  buyExchangeUnit?: Partial<CurrencyDto & GoodsDto & SkuIdDto>;
  comment?: string;
  /** @format date-time */
  createdAt: string;
  feeAmount?: number;
  feeExchangeUnit?: Partial<CurrencyDto & GoodsDto & SkuIdDto>;
  /** @format int64 */
  fromBalanceId?: number;
  /** @format int64 */
  id: number;
  paymentStatus: PaymentStatus;
  provider: UserDto;
  requester: UserDto;
  sellAmount?: number;
  sellExchangeUnit?: Partial<CurrencyDto & GoodsDto & SkuIdDto>;
  title: string;
  type: PaymentType;
  telegramInviteLink?: string;
  deepLinkToChat?: string;
}
export interface PaymentFormDto {
  buyCurrency: Partial<CurrencyDto & GoodsDto & SkuIdDto>;
  buyCurrencyAmount: number;
  details?: string;
  moneyReady: boolean;
  /** @format int64 */
  providerId: number;
  sellCurrency: Partial<CurrencyDto & GoodsDto & SkuIdDto>;
  sellCurrencyAmount: number;
  targetField?: TargetField;
  /** @format int64 */
  userId: number;
  utmTag?: string;
}
export interface PaymentFormDtoV3 {
  availableExchangeOptions?: AvailableExchangeOptions;
  betterExchangeRateWidget?: BetterExchangeRateWidgetDto;
  buyExchangeUnit: Partial<CurrencyDto & GoodsDto & SkuIdDto>;
  buyExchangeUnitAmount: number;
  crossExchangeWidget?: CrossExchangeWidgetDto;
  /** @format int64 */
  customerId: number;
  exchangeRateWidgets?: ExchangeRateWidgetDto[];
  intermediaryExchanges?: ExchangeRateWidgetDto[];
  /** @format int64 */
  providerId: number;
  sellExchangeUnit: Partial<CurrencyDto & GoodsDto & SkuIdDto>;
  sellExchangeUnitAmount: number;
  subcategory?: string;
  targetField: TargetField;
}
export interface PaymentFormDtoV5 {
  availableExchangeOptions?: AvailableExchangeOptions;
  betterExchangeRateWidget?: BetterExchangeRateWidgetDto;
  buyExchangeUnit: Partial<CurrencyResponseDto & GoodsResponseDto>;
  buyExchangeUnitAmount: number;
  crossExchangeWidget?: CrossExchangeWidgetDto;
  /** @format int64 */
  customerId: number;
  exchangeRateWidgets?: ExchangeRateWidgetDto[];
  intermediaryExchanges?: ExchangeRateWidgetDto[];
  /** @format int64 */
  providerId: number;
  sellExchangeUnit: Partial<CurrencyResponseDto & GoodsResponseDto>;
  sellExchangeUnitAmount: number;
  subcategory?: string;
  targetField: TargetField;
  isEditable: boolean;
}
export interface PaymentInitializationResponse {
  /** @format int64 */
  id: number;
  telegramInviteLink?: string;
  telegramDeepLink?: string;
}
export interface PaymentInvitationForm {
  buyCurrency: Partial<CurrencyDto & GoodsDto & SkuIdDto>;
  buyCurrencyAmount: number;
  sellCurrency: Partial<CurrencyDto & GoodsDto & SkuIdDto>;
  sellCurrencyAmount: number;
  targetField: TargetField;
}
export interface PaymentMethodDto {
  name: string;
  type: PaymentMethodType;
}
export interface PaymentMethodResponseDto {
  localizedType: string;
  name: string;
  type: PaymentMethodType;
}
export enum PaymentMethodType {
  Cash = 'CASH',
  Transfer = 'TRANSFER',
  Goods = 'GOODS',
}
export interface PaymentMethodsResponseDto {
  id: PaymentMethodType;
  label: string;
  methods: CurrencyPaymentMethodInfo[];
}
export interface PaymentMethodsResponseDtoV3 {
  localizedPaymentMethodType: string;
  methods: string[];
  paymentMethodType: PaymentMethodType;
}
export interface PaymentParticipantDto {
  invitedBy?: string;
  language?: Language;
  role: PaymentParticipantRole;
  telegramId: string;
  tgUsername?: string;
  username?: string;
}
export enum PaymentParticipantRole {
  Provider = 'PROVIDER',
  Customer = 'CUSTOMER',
  ProviderAssistant = 'PROVIDER_ASSISTANT',
  CustomerAssistant = 'CUSTOMER_ASSISTANT',
}
export interface PaymentProcessingHistoryEntryDto {
  /** @format date-time */
  createdAt: string;
  description: string;
  paymentEvent: PaymentEvent;
  telegramId?: string;
}
export enum PaymentStatus {
  Initialized = 'INITIALIZED',
  Cancelled = 'CANCELLED',
  CustomerSent = 'CUSTOMER_SENT',
  ProviderSent = 'PROVIDER_SENT',
  BothSent = 'BOTH_SENT',
  WaitingCustomerSending = 'WAITING_CUSTOMER_SENDING',
  WaitingProviderSending = 'WAITING_PROVIDER_SENDING',
  WaitingProviderConfirmation = 'WAITING_PROVIDER_CONFIRMATION',
  WaitingCustomerConfirmation = 'WAITING_CUSTOMER_CONFIRMATION',
  Success = 'SUCCESS',
  InProgress = 'IN_PROGRESS',
  PreSuccess = 'PRE_SUCCESS',
  Unconfirmed = 'UNCONFIRMED',
  Expired = 'EXPIRED',
}
export interface PaymentStrategyDto {
  strategy: ResellType;
}
export enum PaymentType {
  Transaction = 'TRANSACTION',
  Exchange = 'EXCHANGE',
}
export interface PrioritizedTaskDto {
  /** @format int64 */
  id: number;
  motivation: string;
  /** @format int32 */
  priority: number;
}
export enum PrivacySetting {
  Public = 'PUBLIC',
  Private = 'PRIVATE',
  Whitelist = 'WHITELIST',
  Hidden = 'HIDDEN',
}
export interface Prompts {
  answer: string;
  sorting: string;
  sorting_system: string;
  task: string;
  task_system: string;
}
export interface ProviderExchangerInitWithLocalStorageRequest {
  buyCurrencyAmount?: number;
  buyCurrencyCode: string;
  buyMethodName: string;
  buyMethodType: PaymentMethodType;
  exchangerType?: ExchangerType;
  /** @format int64 */
  providerId: number;
  sellCurrencyAmount?: number;
  sellCurrencyCode: string;
  sellMethodName: string;
  sellMethodType: PaymentMethodType;
}
export interface ProvidersDto {
  contacts: UserDto[];
  special: UserDto[];
}
export interface PushNotificationDto {
  body: string;
  title: string;
  url: string;
}
export interface PushSubscriptionDto {
  p256dh: string;
  auth: string;
  endpoint: string;
}
export interface UserSubscriptionDto {
  userId: number;
  notificationEvent: NotificationEvent;
  bot: boolean;
  push: boolean
}
export enum NotificationEvent {
  balanceTransaction = 'BALANCE_TRANSACTION',
  newBalance = 'NEW_BALANCE',
  newBalanceAssistant = 'NEW_BALANCE_ASSISTANT',
  newBalanceName = 'NEW_BALANCE_NAME',
  newExchange = 'NEW_EXCHANGE',
  exchangeCompleted = 'EXCHANGE_COMPLETED',
  exchangeCanceled = 'EXCHANGE_CANCELED',
  exchangeUpdatedStatus = 'UPDATED_EXCHANGE_STATUS',
  invitationAccept = 'INVITATION_ACCEPTED',
  invitationAlreadyUsed = 'INVITATION_ALREADY_USED',
  invitationExpired = 'INVITATION_EXPIRED',
  bulkNotifications = 'BULK_NOTIFICATIONS'
}
export interface QAndADto {
  answer: string;
  question: string;
}
export interface RefreshTokenRequest {
  refreshToken?: string;
}
export enum ResellType {
  Default = 'DEFAULT',
  ChainPayments = 'CHAIN_PAYMENTS',
  SquashPayments = 'SQUASH_PAYMENTS',
}
export interface RoleDto {
  authorities: Authority[];
  displayName: string;
  name: string;
}
export interface SetFeeRequest {
  feeRate: number;
  lowerBound: number;
  /** @format int64 */
  userId: number;
}
export interface SetFeeRequestV2 {
  feeRate: number;
  lowerBound: number;
  type: string;
  /** @format int64 */
  userId: number;
}
export interface SkuDescription {
  category: string;
  description: string;
  images: string[];
  modelIdentifier: string;
  modelName: string;
  previewImage: string;
  /** @format int32 */
  priority?: number;
  subcategory: string;
}
export interface SkuModelDto {
  availableSkuParameters: AvailableSkuParameter[];
  /** @format int64 */
  id: number;
  localizedModelName: string;
  measureUnits: MeasureUnitDto[];
  modelIdentifier: string;
  modelName: string;
  picture?: string;
  /** @format int32 */
  priority: number;
}
export interface SkuOffer {
  location: string;
  measureUnit: string;
  modelIdentifier: string;
  skuIdentifier: string;
  /** @uniqueItems true */
  skuProperties: SkuParameter[];
  usdtPrice: number;
}
export interface SkuParameter {
  name: string;
  type: SkuParameterType;
  value: string;
}
export interface SkuParameterDto {
  name: string;
  type: SkuParameterType;
  value: string;
}
export interface SkuParameterResponseDto {
  localizedName: string;
  localizedValue: string;
  name: string;
  type: SkuParameterType;
  value: string;
}
export enum SkuParameterType {
  Enum = 'ENUM',
  Color = 'COLOR',
}
export interface SocialNetworkRequestDto {
  type: ContactType;
  value: string;
}
export interface SocialNetworkResponseDto {
  modifiable: boolean;
  type: ContactType;
  value: string;
}
export enum SortContactsFilterType {
  MaxBalanceValue = 'MAX_BALANCE_VALUE',
  LastInteractionTime = 'LAST_INTERACTION_TIME',
  IdDesc = 'ID_DESC',
}
export interface SortObject {
  empty?: boolean;
  sorted?: boolean;
  unsorted?: boolean;
}
export interface SubcategoryDto {
  localizedName: string;
  name: string;
}
export interface SubcategoryDtoV4 {
  localizedSubcategoryName: string;
  skuModels: SkuModelDto[];
  subcategoryName: string;
}
export interface SubscriptionDto {
  endpoint: string;
  keys: Keys;
}
export interface SwitchInheritanceRequest {
  /** @format int64 */
  exchangeRateId: number;
  /** @format int64 */
  providerId: number;
}
export interface SwitchInheritanceRequestWithPrivacySetting {
  /** @format int64 */
  exchangeOfferId: number;
  privacySetting: PrivacySetting;
  /** @format int64 */
  providerId: number;
}
export enum TabType {
  Currency = 'CURRENCY',
  Goods = 'GOODS',
}
export enum TargetField {
  SellCurrency = 'SELL_CURRENCY',
  BuyCurrency = 'BUY_CURRENCY',
}
export interface TaskAssessmentResponseDto {
  isContextLost: boolean;
  motivation: string;
  question: string;
  /** @format int32 */
  score: number;
  task: string;
  title: string;
}
export interface TaskAssessmentWithRequestHistoryDto {
  openAIRequestHistoryDto: OpenAIRequestHistoryDto;
  taskAssessmentResponseDto: TaskAssessmentResponseDto;
}
export interface TaskParticipantDto {
  role: TaskParticipantRole;
  user: UserDto;
}
export enum TaskParticipantRole {
  Owner = 'OWNER',
  Participant = 'PARTICIPANT',
}
export interface TaskPrioritizationResponseDto {
  openAIRequestHistory: OpenAIRequestHistoryDto;
  tasks: PrioritizedTaskDto[];
}
export interface TaskResponseDto {
  /** @format date-time */
  closedAt?: string;
  currentQuestion?: string;
  /** @format int64 */
  id: number;
  isPrioritized: boolean;
  modifiedTask?: string;
  motivation?: string;
  originalTask: string;
  participants: TaskParticipantDto[];
  /** @format int32 */
  priority?: number;
  qandA: QAndADto[];
  /** @format float */
  score?: number;
  status: TaskStatus;
  title?: string;
  comment?: string;
  history: TaskHistoryDto[];
}
export interface TaskHistoryDto {
  type: TaskHistoryType,
  event: TaskHistoryEvent,
  description: String,
  user?: UserDto,
  /** @format date-time */
  createdAt: string,
}
export enum TaskHistoryEvent {
  Draft = 'DRAFT',
  ReadyForWork = 'READY_FOR_WORK',
  InProgress = 'IN_PROGRESS',
  Postponed = 'POSTPONED',
  Done = 'DONE',
  Resumed = 'RESUMED',
  Refined = 'REFINED',
  PriorityChanged = 'PRIORITY_CHANGED',
}
export enum TaskHistoryType {
  System = 'SYSTEM',
  UserAction = 'USER_ACTION',
  LifeCycle = 'LIFE_CYCLE'
}
export enum TaskStatus {
  ToBeFormulated = 'TO_BE_FORMULATED',
  ReadyForWork = 'READY_FOR_WORK',
  InProgress = 'IN_PROGRESS',
  Postponed = 'POSTPONED',
  Done = 'DONE',
}
export interface TransactionResponseDto {
  balance: BalanceWOBucketsDto;
  bucket: BalanceBucketNoHistoryDto;
  transaction: BalanceTransactionDto;
}
export interface UploadExchangeRateDto {
  buyingCurrencyCode: string;
  buyingMethodName: string;
  buyingMethodType: PaymentMethodType;
  exchangeRate: number;
  /** @format date-time */
  externalCreationTimestamp?: string;
  sellingCurrencyCode: string;
  sellingMethodName: string;
  sellingMethodType: PaymentMethodType;
}
export interface UploadedFileDto {
  name: string;
  url: string;
}
export interface UserDto {
  /** @format date-time */
  accountRegistrationDate: string;
  contactRelationType: ContactRelationType;
  contactUrl?: string;
  contactTgDeepLink?: string;
  customName?: string;
  customPhotoUrl?: string;
  /** @format int64 */
  id: number;
  language?: Language;
  profilePictureUrl?: string;
  resellStrategy: ResellType;
  roleInfo: RoleDto;
  username: string;
}
export interface UserDtoForContacts {
  contactRelationType: ContactRelationType;
  userDto: UserDto;
}
export interface UserIdExchangeIdDto {
  /** @format int64 */
  exchangeOfferId: number;
  /** @format int64 */
  userId: number;
}
export enum UserRelationType {
  Customer = 'CUSTOMER',
  Contact = 'CONTACT',
}
export interface UserTgLoginRequest {
  invitationToken?: string;
  language: Language;
  telegramPayload: Record<string, string>;
}
export interface UsersSeparatedByWhitelist {
  inWhiteList: UserDto[];
  notInList: UserDto[];
}
