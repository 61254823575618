import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Layout } from 'components/Layout/Layout';
import {
  useChangePostponedToReadyForWorkStatusMutation,
  useCompleteTaskMutation,
  useDeleteTaskMutation,
  useGetTaskByIdQuery,
  usePrioritizeTasksMutation,
} from 'services/tasks';
import Button, { Variant } from 'components/Button/Button';
import ArchiveInboxIcon from 'components/icons/ArchiveInbox';
import AIPrioritizedIcon from 'components/icons/AIPrioritized';
import ArchiveOutboxIcon from 'components/icons/ArchiveOutbox';
import DeleteIcon from 'components/icons/Delete';
import TaskInfo from '../components/TaskInfo';
import { TASK_MAIN } from 'pages/paths';
import SkeletonWithWrapper from 'shared/ui/SkeletonWithWrapper';
import { ModalTypes, open } from 'slices/modal';
import TaskFormulatingModal from 'widgets/TaskFormulatingModal';
import { TaskStatus } from 'dtos';
import cn from 'classnames';
import styles from './index.module.scss';
import { FOCUS_TASK, PRIORITIZE } from 'const';
import PostponeTaskModal from 'widgets/PostponeTaskModal';
import HistoryList from 'components/icons/HistoryList';

const TaskPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const taskId = Number(id);
  const [isNeedPrioritize, setPrioritize] = useState(false);

  const [completeTask, { isLoading: isCompleting }] = useCompleteTaskMutation();
  const [prioritizeTasks, { isLoading: isPrioritizing }] = usePrioritizeTasksMutation();
  const [deleteTask, { isLoading: isDeleting }] = useDeleteTaskMutation();
  const [changePostponedToReadyForWorkStatus, { isLoading: isUnblocking }] =
    useChangePostponedToReadyForWorkStatusMutation();

  const { data: task, isLoading } = useGetTaskByIdQuery(taskId);

  if (isLoading) {
    return (
      <Layout.Content>
        <SkeletonWithWrapper count={3} height={110} />
      </Layout.Content>
    );
  }

  if (task === undefined) {
    navigate(TASK_MAIN);
    return null;
  }

  if (task.status === TaskStatus.InProgress) {
    localStorage.setItem(FOCUS_TASK, String(task.id));
  }

  const onAddNewTaskButtonClick = () => {
    dispatch(
      open({
        id: ModalTypes.TaskFormulating,
        data: {},
      })
    );
  };

  const onSetTaskButtonClick = async () => {
    dispatch(
      open({
        id: ModalTypes.TaskFormulating,
        data: { task, isNeedInitialAssessment: true },
      })
    );
  };

  const onCloseButtonClick = () => {
    if (isNeedPrioritize) {
      localStorage.setItem(PRIORITIZE, 'true');
    }
    navigate(TASK_MAIN);
  };

  const onCompleteButtonClick = async () => {
    try {
      await completeTask(taskId);
      await toast.promise(prioritizeTasks(), {
        loading: t('status.aiPrioritizing'),
        success: () => <b>{t('status.success')}</b>,
        error: () => <b>{t('status.smthWrong')}</b>,
      });
      navigate(TASK_MAIN);
    } catch (e) {
      toast.error(t('status.smthWrong'));
    }
  };

  const onDeleteButtonClick = async () => {
    navigate(TASK_MAIN);
    await toast.promise(deleteTask(taskId), {
      loading: t('status.deleting'),
      success: () => {
        return <b>{t('status.deleted')}</b>;
      },
      error: () => {
        navigate(`${TASK_MAIN}/${taskId}`)
        return <b>{t('status.smthWrong')}</b>;
      },
    });
  };

  const onPostponeButtonClick = async () => {
    dispatch(
      open({
        id: ModalTypes.PostponeTaskModal,
        data: { task: task },
      })
    );
  };

  const onUnblockButtonClick = async () => {
    await toast.promise(changePostponedToReadyForWorkStatus(taskId), {
      loading: t('status.processing'),
      success: () => {
        return <b>{t('status.success')}</b>;
      },
      error: () => <b>{t('status.smthWrong')}</b>,
    });
  };

  let onTopButtonClick;
  let topButtonText;
  if (task.status === TaskStatus.InProgress) {
    topButtonText = t('page.taskInfo.buttons.complete');
    onTopButtonClick = onCompleteButtonClick;
  } else {
    topButtonText = t('page.taskInfo.buttons.close');
    onTopButtonClick = onCloseButtonClick;
  }
  const isDeleteButtonVisible = [
    TaskStatus.ToBeFormulated,
    TaskStatus.ReadyForWork,
    TaskStatus.Postponed,
  ].includes(task.status);
  const isPostponeButtonVisible = [TaskStatus.ReadyForWork, TaskStatus.InProgress].includes(
    task.status
  );
  const isSetTaskButtonVisible = task.status === TaskStatus.ToBeFormulated;
  const isUnblockButtonVisible = task.status === TaskStatus.Postponed;
  const isAddNewTaskButtonVisible = task.status === TaskStatus.InProgress;

  const onHistoryButtonClick = () => {
    navigate(`/task/${taskId}/history`)
  }

  return (
    <Layout>
      <Layout.TopBar className={styles.header}>
        <div className={cn(styles.button, styles.historyButton)} onClick={onHistoryButtonClick}>
          <HistoryList className={styles.historyIcon} />
        </div>
        <Button
          variant={Variant.Minimal}
          className={cn(styles.button, styles.topButton)}
          onClick={onTopButtonClick}
          disabled={isCompleting || isPrioritizing}
        >
          {topButtonText}
        </Button>
      </Layout.TopBar>
      <Layout.Content unsetTopPadding>
        <TaskInfo task={task} />

        <div className={styles.buttons}>
          {isPostponeButtonVisible && (
            <Button
              variant={Variant.Minimal}
              className={cn(styles.button, styles.bottomButton)}
              onClick={onPostponeButtonClick}
            >
              <div className={styles.buttonChild}>
                <ArchiveInboxIcon className={styles.buttonIcon} />
                <span>{t('page.taskInfo.buttons.postpone')}</span>
              </div>
            </Button>
          )}

          {isSetTaskButtonVisible && (
            <Button
              variant={Variant.Minimal}
              className={cn(styles.button, styles.bottomButton)}
              onClick={onSetTaskButtonClick}
            >
              <div className={styles.buttonChild}>
                <AIPrioritizedIcon className={styles.buttonIcon} />
                <span>{t('page.taskInfo.buttons.setTask')}</span>
              </div>
            </Button>
          )}

          {isUnblockButtonVisible && (
            <Button
              variant={Variant.Minimal}
              className={cn(styles.button, styles.bottomButton)}
              disabled={isUnblocking}
              onClick={onUnblockButtonClick}
            >
              <div className={styles.buttonChild}>
                <ArchiveOutboxIcon className={styles.buttonIcon} />
                <span>{t('page.taskInfo.buttons.unblock')}</span>
              </div>
            </Button>
          )}

          {isDeleteButtonVisible && (
            <Button
              variant={Variant.Minimal}
              className={cn(styles.button, styles.bottomButton, styles.deleteButton)}
              disabled={isDeleting}
              onClick={onDeleteButtonClick}
            >
              <div className={styles.buttonChild}>
                <DeleteIcon className={styles.buttonIcon} />
                <span>{t('page.taskInfo.buttons.delete')}</span>
              </div>
            </Button>
          )}

          {isAddNewTaskButtonVisible && (
            <Button
              variant={Variant.Minimal}
              className={cn(styles.button)}
              onClick={onAddNewTaskButtonClick}
            >
              {t('page.taskInfo.buttons.addNewTask')}
            </Button>
          )}
        </div>
        <TaskFormulatingModal />
        <PostponeTaskModal setPrioritize={setPrioritize} />
      </Layout.Content>
    </Layout>
  );
};

export default TaskPage;
